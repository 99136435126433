import { Injectable } from '@angular/core';
import { AdditionalContent, AdditionalContentTranslation, AudioGuide, AudioGuideTranslation, MainImage, MainImageTranslation, MediaFile, Station, StationTranslation } from '../types/station';
import { ContentService } from './content.service';
import { DirectusService } from './directus.service';
import { MediaFileService } from './media-file.service';

@Injectable({
  providedIn: 'root'
})
export class StationService {

  public stations:Station[];

  constructor(
    private contentService:ContentService, 
    private directusService:DirectusService,
    private mediaFileService:MediaFileService) {
    
  }

  async getStationIdByStationNumber(num:number):Promise<number>{

    if(!this.stations){
      await this.getAllStation();
    }

    for(var i=0; i < this.stations.length; i++){
      if(this.stations[i].station_number == num){
        return i;
      }
    }
    return -1;
  }

  async getStationNumberByStationID(num:number):Promise<number>{

    if(!this.stations){
      await this.getAllStation();
    }
    if(num < 0 || num > this.stations.length-1){
      return 1;
    }else{
      return this.stations[num].station_number;
    }
  }
  async getAllStation():Promise<boolean>{

    if(!this.stations){
      var call = this.loginIfNeeded().then(async result =>{
        const request = await this.directusService.api.getItems<Station[]>('station');
        this.stations = request.data;

        this.stations.sort((a, b) => a.station_number - b.station_number);
        
        return true;
      });
      return (await call);
    }else{
      return true;
    }
  }

  async loginIfNeeded(){
    return this.checkLoginState().then(isLoggedIn=>{
      if(isLoggedIn == true){
        return true;
      }else{
        return this.login().then(result=>{
          return true;
        })
      }
    });
  }

  async checkLoginState(){
    const loggendIn = await this.directusService.checkStaus();
    return loggendIn;
  }

  async login(){
    const result = await this.directusService.login();
    return result.data;
  }


  public prepareStation(station:Station){

    if(station.prepared)return;

    station.prepared = true;

    station.translations = [this.CreateDefaultTranslationForStation(station)];

    //GET STATION TRANSLATION
    this.contentService.getTranslationForStation(station.id,"en").subscribe(result=>{
      if(result.data.length>0){
        station.translations.push(result.data[0]);
      }else{
        station.translations.push(this.CreateDefaultTranslationForStation(station));
      }

      this.contentService.getTranslationForStation(station.id,"fr").subscribe(result=>{
        if(result.data.length>0){
          station.translations.push(result.data[0]);
        }else{
          station.translations.push(this.CreateDefaultTranslationForStation(station));
        }
        station.translationsReady = true;
      });
    });
    //GET MAIN IMAGE
    this.contentService.getMainImageForStation(station.id).subscribe(result=>{
      station.mainImage = result.data[0];
      station.mainImage.translations = [this.CreateDefaultTranslationForMainImage(station.mainImage)];
      this.contentService.getFiles(station.mainImage.image_file).subscribe(result=>{
          station.mainImage.mediaFile = this.mediaFileService.CreateMediaFile(result.data[0]);
      });

      this.contentService.getTranslationForMainImage(station.mainImage.id,"en").subscribe(result=>{

        if(result.data.length>0){
          station.mainImage.translations.push(result.data[0]);
        }else{
          station.mainImage.translations.push(this.CreateDefaultTranslationForMainImage(station.mainImage));
        }

        this.contentService.getTranslationForMainImage(station.mainImage.id,"fr").subscribe(result=>{
          if(result.data.length>0){
            station.mainImage.translations.push(result.data[0]);
          }else{
            station.mainImage.translations.push(this.CreateDefaultTranslationForMainImage(station.mainImage));
          }
          station.mainImage.translationsReady = true;
        });
      });
    });
    
    //GET AUDIO GUIDE
    this.contentService.getAudioGuideForStation(station.id).subscribe(result=>{
      station.audioGuide = result.data[0];
      
      this.contentService.getFiles(station.audioGuide.audio_clip).subscribe(result=>{
        
        station.audioGuide.mediaFile = this.mediaFileService.CreateMediaFile(result.data[0]);
        station.audioGuide.translations = [this.CreateDefaultTranslationForAudioguide(station.audioGuide)];

        this.contentService.getTranslationForAudioGuide(station.audioGuide.id,"en").subscribe(result=>{

          if(result.data.length>0){
            
            this.contentService.getFiles(result.data[0].audio_clip).subscribe(fileData=>{
              result.data[0].mediaFile = this.mediaFileService.CreateMediaFile(fileData.data[0]);
            })
            station.audioGuide.translations.push(result.data[0]);
  
          }else{
            station.audioGuide.translations.push(this.CreateDefaultTranslationForAudioguide(station.audioGuide));
          }
  
          this.contentService.getTranslationForAudioGuide(station.audioGuide.id,"fr").subscribe(result=>{
  
            if(result.data.length>0){
              this.contentService.getFiles(result.data[0].audio_clip).subscribe(fileData=>{
                result.data[0].mediaFile = this.mediaFileService.CreateMediaFile(fileData.data[0]);
              })
              station.audioGuide.translations.push(result.data[0]);
            }else{
              station.audioGuide.translations.push(this.CreateDefaultTranslationForAudioguide(station.audioGuide));
            }
            station.audioGuide.translationsReady = true;
          });
        });
      });
    });
    
    //GET ADDITIONAL CONTENT
    this.contentService.getAdditionalContentForStation(station.id).subscribe(result=>{
      station.additionalContent = result.data;
      station.additionalContent.sort((a, b) => a.order_id - b.order_id);

      station.additionalContent.forEach(element => {

        element.translations = [this.CreateDefaultTranslationForAdditional(element)];
        this.contentService.getMediaFileIdsForAdditionalContent(element.id).subscribe(result=>{
          element.translations[0].mediaFiles = [];

          result.data.forEach(mediaElement => {
            this.contentService.getFiles(mediaElement.directus_files_id).subscribe(result=>{
              element.translations[0].mediaFiles.push(this.mediaFileService.CreateMediaFile(result.data[0]));
              element.translations[0].mediaFiles.sort((a, b) => (a.title.localeCompare(b.title)));
            })
          });

          this.contentService.getTranslationForAdditionalContent(element.id,"en").subscribe(result=>{
            if(result.data.length > 0){
              if(result.data[0].usegermanfiles){
                result.data[0].media_files = element.media_files;
                result.data[0].mediaFiles = element.translations[0].mediaFiles;
              }
              element.translations.push(result.data[0]);

              if(!result.data[0].usegermanfiles){
                this.GetMediafilesForAdditionalTranslation(element.translations[1]);
              }

            }else{
              element.translations.push(null);
            }
            this.contentService.getTranslationForAdditionalContent(element.id,"fr").subscribe(result=>{
              if(result.data.length > 0){
                if(result.data[0].usegermanfiles){
                  result.data[0].media_files = element.media_files;
                  result.data[0].mediaFiles = element.translations[0].mediaFiles;
                }
                element.translations.push(result.data[0]);
  
                if(!result.data[0].usegermanfiles){
                  this.GetMediafilesForAdditionalTranslation(element.translations[2]);
                }
  
              }else{
                element.translations.push(null);
              }
              element.translationsReady = true;
            });
          });
        });
      });
    });
  }

  CreateDefaultTranslationForStation(element:Station):StationTranslation{
    var trans = <StationTranslation>{};
    trans.headline = element.headline;
    trans.route_next_station = element.route_next_station;
    return trans;
  }

  CreateDefaultTranslationForMainImage(element:MainImage):MainImageTranslation{
    var trans = <MainImageTranslation>{};
    trans.caption = element.caption;
    trans.image_description = element.image_description;
    trans.alt_text = element.alt_text;
    trans.headline = element.headline;
    return trans;
  }

  CreateDefaultTranslationForAudioguide(element:AudioGuide):AudioGuideTranslation{
    var trans = <AudioGuideTranslation>{};
    trans.audio_transscript = element.audio_transscript;
    trans.alt_text = element.alt_text;
    trans.mediaFile = element.mediaFile;
    return trans;
  }

  CreateDefaultTranslationForAdditional(element:AdditionalContent):AdditionalContentTranslation{
    var trans = <AdditionalContentTranslation>{};
    trans.headline = element.headline;
    trans.alt_text = element.alt_text;
    trans.description = element.description;
    trans.caption = element.caption;
    trans.usegermanfiles = true;
    return trans;
  }

  

  GetMediafilesForAdditionalTranslation(element:AdditionalContentTranslation){
    this.contentService.getMediaFileIdsForAdditionalContentTranslation(element.id).subscribe(result=>{
      element.mediaFiles = [];

      result.data.forEach(mediaElement => {
        this.contentService.getFiles(mediaElement.directus_files_id).subscribe(result=>{
          element.mediaFiles.push(this.mediaFileService.CreateMediaFile(result.data[0]));
          element.mediaFiles.sort((a, b) => (a.title.localeCompare(b.title)));
        })
      });
    });
  }
}

