<app-main-menu-button></app-main-menu-button>

<div *ngIf="!appstateService.isSmallScreen" [ngClass]="'desktop'">
    <app-hotel-silber-logo id="logo" *ngIf="!appstateService.isMenuOpen"></app-hotel-silber-logo>
    <app-main-menu *ngIf="appstateService.isMenuOpen"></app-main-menu>
    <app-language-menu [ngClass]="'desktop'"></app-language-menu>
</div>

<div *ngIf="appstateService.isSmallScreen && appstateService.isMenuOpen">
    <app-language-menu [ngClass]="'mobile'"></app-language-menu>
</div>