import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';
import { VideoSubtitleServiceService } from 'src/app/services/video-subtitle-service.service';
import { MediaFile } from 'src/app/types/station';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {
  @Input() mediaFile:MediaFile;
  @Input() caption:string;
  @Input() altText:string;

  constructor(private videoSubService:VideoSubtitleServiceService, public langService:LanguageService) { }

  deSub = null;
  enSub = null;
  frSub = null;

  ngOnInit(): void {
    if(this.mediaFile.subtitleFiles === null){
      this.loadSubs();
    }
    
  }

  async loadSubs(){
    this.mediaFile = await this.videoSubService.getSubtitleFilesForVideo(this.mediaFile);

    var subs = this.mediaFile.subtitleFiles;

    try{
      if(subs[0]!==null)this.deSub = subs[0].filePath;
      if(subs[1]!==null)this.enSub = subs[1].filePath;
      if(subs[2]!==null)this.frSub = subs[2].filePath;
    }catch(e){
      console.error(e);
    }
    

  }

}
