import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AppstateService } from 'src/app/services/appstate.service';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.css']
})
export class NavigationBarComponent implements OnInit {

  constructor(
    public appstateService:AppstateService,
    private router: Router,

  ) { }

  ngOnInit(): void {
  }

  onMenuButtonClicked(event){
  }


}
