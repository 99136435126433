<ng-container *ngIf="audioGuide.mediaFile && audioGuide.translations && audioGuide.translations[languageService.langID] && audioGuide.translations[languageService.langID].mediaFile">
    
    <app-audio id="mediaGuide" [resize]="!appstateService.isSmallScreen" [mediaFile]="audioGuide.translations[languageService.langID].mediaFile" [caption]="" [altText]="audioGuide.translations[languageService.langID].alt_text" [headline]="headline">

    </app-audio>

    <h2>
        <button class=textButton (click)="toogleMore()">
            
        {{languageService.getTextByKey("STATION_AUDIO_MORE")}}&nbsp;<span [ngClass]="[!showMore ? 'moreIcon' : 'lessIcon']"></span>
        </button>
    </h2>
    
    <p *ngIf="showMore" [ngClass]="'moreInfo'" [innerHTML]="audioGuide.translations[languageService.langID].audio_transscript">
    </p>
</ng-container>

