import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ContentService } from 'src/app/services/content.service';
import { LanguageService } from 'src/app/services/language.service';
import { OtherContentService } from 'src/app/services/other-content.service';
import { Imprint } from 'src/app/types/screens';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.css']
})
export class ImprintComponent implements OnInit {

  constructor(public otherContentService:OtherContentService,public contentService:ContentService,public languageService:LanguageService,private title:Title) { }

  data:Imprint;

  ngOnInit(): void {
    this.otherContentService.getImprintContent().then(result=>{
      result.subscribe(contentData=>{
        this.data = contentData.data[0];

        this.data.translations = [this.otherContentService.CreateDefaultTranslationForImprint(this.data)];

        this.otherContentService.getImprintContentTranslation(this.data.id,"en").then(result=>{
          result.subscribe(contentData=>{
            if(contentData.data.length > 0){
              this.data.translations.push(contentData.data[0]);
            }else{
              this.data.translations.push(this.otherContentService.CreateDefaultTranslationForImprint(this.data));
            }
          })
          this.otherContentService.getImprintContentTranslation(this.data.id,"fr").then(result=>{
            result.subscribe(contentData=>{
              if(contentData.data.length > 0){
                this.data.translations.push(contentData.data[0]);
              }else{
                this.data.translations.push(this.otherContentService.CreateDefaultTranslationForImprint(this.data));
              }

              this.title.setTitle("HOTEL SILBER: "+this.data.translations[this.languageService.langID].headline)

            })
          });
        });
      })
    });
  }

}
