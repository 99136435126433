import { Component, Input, OnInit } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { LanguageService } from 'src/app/services/language.service';
import { AdditionalContent } from 'src/app/types/station';

@Component({
  selector: 'app-additional-content',
  templateUrl: './additional-content.component.html',
  styleUrls: ['./additional-content.component.css']
})
export class AdditionalContentComponent implements OnInit {
  @Input() additional:AdditionalContent;
  showMore = false;
  public index:number = 0;


  constructor(
    public languageService:LanguageService,
    public contentService:ContentService
  ) { }

  ngOnInit(): void {
    
  }

  toogleMore(){
    this.showMore = !this.showMore;
  }

  showNextImage(){
    this.index++;
    if(this.index>this.additional.translations[this.languageService.langID].mediaFiles.length-1){
      this.index = this.additional.translations[this.languageService.langID].mediaFiles.length-1;
    }
  }

  showPrevImage(){
    this.index--;
    if(this.index<0)this.index=0;
  }

}
