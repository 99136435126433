import { Component, OnInit } from '@angular/core';
import { OtherContentService } from 'src/app/services/other-content.service';
import { Visit } from 'src/app/types/screens';

@Component({
  selector: 'app-visit',
  templateUrl: './visit.component.html',
  styleUrls: ['./visit.component.css']
})
export class VisitComponent implements OnInit {

  constructor(public otherContentService:OtherContentService) { }

  data:Visit;

  ngOnInit(): void {
    this.otherContentService.getVisitContent().then(result=>{
      result.subscribe(contentData=>{
        this.data = contentData.data[0];
      })
    });
  }

}
