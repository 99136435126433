import { DebugElement, Injectable, Query } from '@angular/core';
import { ICollectionResponse } from '@directus/sdk-js/dist/types/schemes/response/Collection';
import { IItemResponse, IItemsResponse } from '@directus/sdk-js/dist/types/schemes/response/Item';
import { Observable, from, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { QueryParams as QueryParamsType, AssetQueryParams as AssetQueryParamsType } from "@directus/sdk-js/dist/types/schemes/http/Query";

import { DirectusService } from './directus.service';

import { Station, MainImage, AudioGuide, AdditionalContent, AdditionalContentTranslation, StationTranslation, MainImageTranslation, AudioGuideTranslation, AdditionalContentFileJunction, AdditionalContentTranslationFileJunction } from '../types/station'
import { IFilesResponse } from '@directus/sdk-js/dist/types/schemes/response/File';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  constructor(
    private directusService:DirectusService,  
    private http: HttpClient
    ) {}

  async getFirstStationId():Promise<number>{
    const result = await this.directusService.api.getItems<Station[]>('station');
    var stations = result.data;
    stations.sort((a, b) => a.station_number - b.station_number);
    return stations[0].id;
  }

  getStation(itemID:number):Observable<IItemResponse<Station>>{
    return from(this.directusService.api.getItem<Station>('station',itemID));
  }

  getStations():Observable<IItemResponse<Station[]>>{
    return from(this.directusService.api.getItems<Station[]>('station'));
  }

  getTranslationForStation(stationID:number, lang:string):Observable<IItemResponse<StationTranslation[]>>{
    const params: QueryParamsType = {
      filter:{
        ["station_id"]:{eq: stationID},
        ["language"]:{eq: lang}
      }
    }
    return from(this.directusService.api.getItems<StationTranslation[]>('station_translation',params))
  }

  getMainImageForStation(stationID:number):Observable<IItemsResponse<MainImage[]>>{
    const params: QueryParamsType = {
      filter:{
        ["station"]:{eq: stationID}
      }
    }
    return from(this.directusService.api.getItems<MainImage[]>('main_image',params));
  }

  getTranslationForMainImage(mainImageID:number, lang:string):Observable<IItemResponse<MainImageTranslation[]>>{
    const params: QueryParamsType = {
      filter:{
        ["main_image_id"]:{eq: mainImageID},
        ["language"]:{eq: lang}
      }
    }
    return from(this.directusService.api.getItems<MainImageTranslation[]>('main_image_translation',params))
  }

  getAudioGuideForStation(stationID:number):Observable<IItemsResponse<AudioGuide[]>>{
    const params: QueryParamsType = {
      filter:{
        ["station"]:{eq: stationID}
      }
    }
    return from(this.directusService.api.getItems<AudioGuide[]>('audioguide',params));
  }

  getTranslationForAudioGuide(mainImageID:number, lang:string):Observable<IItemResponse<AudioGuideTranslation[]>>{
    const params: QueryParamsType = {
      filter:{
        ["audioguide_id"]:{eq: mainImageID},
        ["language"]:{eq: lang}
      }
    }
    return from(this.directusService.api.getItems<AudioGuideTranslation[]>('audioguide_translation',params))
  }

  getAdditionalContentForStation(stationID:number):Observable<IItemsResponse<AdditionalContent[]>>{
    const params: QueryParamsType = {
      filter:{
        ["station"]:{eq: stationID}
      }
    }
    return from(this.directusService.api.getItems<AdditionalContent[]>('additional_contet',params));
  }

  getMediaFileIdsForAdditionalContent(additionalContetID:number):Observable<IItemsResponse<AdditionalContentFileJunction[]>>{
    const params: QueryParamsType = {
      filter:{
        ["additional_contet_id"]:{eq: additionalContetID},
      }
    }
    return from(this.directusService.api.getItems<AdditionalContentFileJunction[]>('additional_contet_directus_files',params));
  }

  getMediaFileIdsForAdditionalContentTranslation(additionalContetTranslationID:number):Observable<IItemsResponse<AdditionalContentTranslationFileJunction[]>>{
    const params: QueryParamsType = {
      filter:{
        ["additional_content_translation_id"]:{eq: additionalContetTranslationID},
      }
    }
    return from(this.directusService.api.getItems<AdditionalContentFileJunction[]>('additional_content_translation_directus_files',params));
  }

  getTranslationForAdditionalContent(additionalContetID:number, lang:string):Observable<IItemResponse<AdditionalContentTranslation[]>>{
    const params: QueryParamsType = {
      filter:{
        ["content_id"]:{eq: additionalContetID},
        ["language"]:{eq: lang}
      }
    }
    return from(this.directusService.api.getItems<AdditionalContentTranslation[]>('additional_content_translation',params))
  }

  getFiles(fileID:number):Observable<IFilesResponse>{
    const params: QueryParamsType = {
      filter:{["id"]:{eq : fileID}}
    }
    return from(this.directusService.api.getFiles(params));
  }


  /*getVideoWithSub():Observable<IItemsResponse<MainImage[]>>{
    return from(this.directusService.api.getItem<Station>('station',itemID));


  }*/

  isEmpty(val:string):boolean{
    if(!val || val == "" || val.length == 0){
      return true;
    }else{
      false;
    }
  }
}


