import { Component, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppstateService } from './services/appstate.service';
import { DirectusService } from './services/directus.service';
import { FilePreviewOverlayService } from './services/file-preview-overlay-service.service';
import { StationService } from './services/station.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
 //host:{"[class.white]":"whitePage"}
export class AppComponent implements OnInit{
  @HostListener('window:resize', ['$event'])
  onScreenResize(event) {
    this.appstateService.checkScreen();
  }

  constructor(
    private directusService:DirectusService,
    public appstateService:AppstateService,
    private stationService:StationService,
    private previewDialog: FilePreviewOverlayService,
    private title:Title,
  ){
    /*appstateService.isWhitePage().subscribe((value)=>{
      this.whitePage = value
    })*/
  }

  //whitePage:boolean = false;

  ngOnInit(){
    this.directusService.ping().subscribe(result=>{
      console.log(result);
    });
    this.title.setTitle("HOTEL SILBER");
  }

  
}
