import { Component, DebugElement, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AudioComponent } from 'src/app/elements/audio/audio.component';
import { AppstateService } from 'src/app/services/appstate.service';
import { LanguageService } from 'src/app/services/language.service';
import { AudioGuide, Station } from 'src/app/types/station';

@Component({
  selector: 'app-audio-guide',
  templateUrl: './audio-guide.component.html',
  styleUrls: ['./audio-guide.component.css'],
})
export class AudioGuideComponent implements OnInit {
  @Input() audioGuide:AudioGuide;
  @Input() stationIndex:Number;
  @ViewChild(AudioComponent) child:AudioComponent;

  headline:string;

  showMore = false;
  
  constructor(
    public languageService:LanguageService,
    public appstateService:AppstateService
  ) { }

  ngOnInit(): void {
    this.headline = 'STATION '+ this.stationIndex;
   
    if(this.stationIndex == 15){
      this.languageService.onLanguageChanged.subscribe(()=>{
        this.headline = 'STATION '+ this.stationIndex;
        this.headline += " | " +this.languageService.getTextByKey("STATION_LASTSTATION");
      });
      this.headline += " | " +this.languageService.getTextByKey("STATION_LASTSTATION");
    }
  }

  toogleMore(){
    this.showMore = !this.showMore;
    console.log(this.audioGuide);
  }
}
