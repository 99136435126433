import { Component, Input, OnInit } from '@angular/core';
import { AppstateService } from 'src/app/services/appstate.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.css']
})
export class MainMenuComponent implements OnInit {
  @Input()isStartPage=false;
  constructor(
    public appstateService:AppstateService,
    public languageService:LanguageService,
  ) { }

  ngOnInit(): void {
    
  }

  onMenuButtonClick(){
    this.appstateService.isMenuOpen = false;
  }

}
