import { Component, OnInit } from '@angular/core';
import { OtherContentService } from 'src/app/services/other-content.service';
import { Hdg } from 'src/app/types/screens';

@Component({
  selector: 'app-hdg',
  templateUrl: './hdg.component.html',
  styleUrls: ['./hdg.component.css']
})
export class HdgComponent implements OnInit {

  constructor(public otherContentService:OtherContentService) { }

  data:Hdg;

  ngOnInit(): void {
    this.otherContentService.getHdgContent().then(result=>{
      result.subscribe(contentData=>{
        this.data = contentData.data[0];
      })
    });
  }

}
