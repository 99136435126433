import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';
import { StationService } from 'src/app/services/station.service';

@Component({
  selector: 'app-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.css']
})
export class CodeComponent implements OnInit {

  public enterdNumber:string="";

  constructor(
    public languageService:LanguageService,
    private stationService:StationService,
    private router: Router,
    private title:Title,
  ) { }

  ngOnInit(): void {
   
    const elms = document.getElementsByClassName('numbersButton');
    const elm = elms[0] as HTMLElement;
    const w = elm.offsetWidth;

    for (let i = 0; i < elms.length; i++) {
        const e = elms[i] as HTMLElement;
        e.style.height = w + "px";
    }
    this.title.setTitle("HOTEL SILBER: "+this.languageService.getTextByKey("MENU_NUMBLOCK"));
  }

  setBackgroundColor(){
    var elm = <HTMLElement>document.getElementsByTagName('Main')[0];
  }

  setNumber(val:string){
    if(this.invalid){
      this.enterdNumber = "";
      this.invalid = false;
    }
    if(this.enterdNumber.length > 1)return;
    this.enterdNumber=this.enterdNumber+val;
  }

  removeLast(){
    this.clearNumbers();
  }

  loadStation(){

    var enterdInt = parseInt(this.enterdNumber, 10);
    this.stationService.getStationIdByStationNumber(enterdInt).then(result=>{

      if(result!=-1){
        this.router.navigate(["station/"+enterdInt]);
      }else{
        this.enterdNumber = this.languageService.getTextByKey("NUMBLOCK_INVALID");
        this.invalid = true;
      } 
    });
       
  }

  invalid:boolean = false;

  clearNumbers(){
    this.enterdNumber = "";
  }

}
