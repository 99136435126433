<div [ngClass]="['audioContainer', appstateService.isSmallScreen ? 'mobile' : 'desktop ']" id="audioGuidePlayer">
    <h2 *ngIf="headline !=''">{{headline}}</h2>

    <div class="audio-controls">
        <button (click)="onSeekBack()"><img src="./assets/Icons/Icons_Hotel_Silber_back.svg" width="100%" height="100%" alt="zurück"></button>
        <button *ngIf="!playing" (click)="onAudioPlay()"><img src="./assets/Icons/Icons_Hotel_Silber_Playbutton.svg" width="100%" height="100%" alt="play"></button>
        <button *ngIf="playing" (click)="onAudioPlay()"><img src="./assets/Icons/Icons_Hotel_Silber_pause.svg" width="100%" height="100%" alt="pause"></button>  
        <button (click)="onSeekForward()"><img src="./assets/Icons/Icons_Hotel_Silber_forward.svg" width="100%" alt="forwärts"></button>
    </div>
    
    <div class="timeDisplay">
        <!--<div #timeline class="timeline"></div>
        <div #timebar class="timeBar"></div>-->
        <input #playbar type="range" min="0" max="100" value="0" class="slider" id="myRange" (change)="onSliderChange($event)">
        {{currentTime}} | {{convertToTimeString(mediaFile.duration)}}  
    </div>
    

    <audio #audioPlayer [title]="altText" no-controls controlsList="nodownload" [src]="mediaFile.filePath" (onpause)="onAudioPause()"></audio>
    
</div>
<div [ngClass]="'caption'">{{caption}}</div>