import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AtHomeSelectionComponent } from './screens/at-home-selection/at-home-selection.component';
import { LandingPageComponent } from './screens/landing-page/landing-page.component';

import { StationComponent } from './station-elements/station/station.component';
import { PageNotFoundComponent } from './screens/page-not-found/page-not-found.component';
import { MainMenuComponent } from './menu/main-menu/main-menu.component';
import { AboutComponent } from './screens/about/about.component';
import { HelpComponent } from './screens/help/help.component';
import { VisitComponent } from './screens/visit/visit.component';
import { HdgComponent } from './screens/hdg/hdg.component';
import { ImprintComponent } from './screens/imprint/imprint.component';
import { CodeComponent } from './screens/code/code.component';
import { FeedbackComponent } from './screens/feedback/feedback.component';
import { PortraitComponent } from './screens/portrait/portrait.component';
import { PrivacyComponent } from './screens/privacy/privacy.component';

const routes: Routes = [
  { path: '', redirectTo: '/start', pathMatch: 'full' },

  { path: 'start', component: LandingPageComponent },
  { path: 'about', component: AboutComponent },
  { path: 'help', component: HelpComponent },
  { path: 'visit', component: VisitComponent },
  { path: 'HdG', component: HdgComponent },
  { path: 'imprint', component: ImprintComponent },
  { path: 'code', component: CodeComponent },
  { path: 'feedback', component: FeedbackComponent },
  { path: 'privacy', component: PrivacyComponent },
  
  { path: 'atHome', component: AtHomeSelectionComponent  },
  { path: 'menu', component: MainMenuComponent },
  { path: 'station', component: StationComponent },
  { path: 'station/:id', component: StationComponent },
  { path: '**', component: PageNotFoundComponent }
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }