import { EventEmitter, Injectable } from '@angular/core';
import { Console } from 'console';
import { element } from 'protractor';
import { Catergory } from '../types/feedback';
import { DefaultTextelement, Textelement } from '../types/textData';
import { ContentService } from './content.service';
import { DirectusService } from './directus.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private textElements:Textelement[]

  private defaultTE:DefaultTextelement[] = [
    {key:"MENU_ABOUT",german:"Über die Ausstellung"},
    {key:"MENU_AUDIOGUIDE",german:"Audioguide"},
    {key:"MENU_HELP",german:"Hilfe"},
    {key:"MENU_VISIT",german:"Besuch"},
    {key:"MENU_HDG",german:"Haus der Geschichte"},
    {key:"MENU_IMPRINT",german:"Impressum"},
    {key:"MENU_NUMBLOCK",german:"Nummerneingabe"},
    {key:"MENU_FEEDBACK",german:"Feedback"},
    {key:"MENU_PRIVACY",german:"Datenschutz"},
    

    {key:"STATION_LANDING_STARTTOUR",german:"Start der Tour"},
    {key:"STATION_AUDIOGUIDE_HEADLINE",german:"Zusammenfassung anzeigen"},
    {key:"STATION_SHOWWAY_HEADLINE",german:"Weg zur nächsten Station"},
  ]

  public test:string = "deafult";

  public langID:number = 0;
  langName:string = "de";
  
  public onLanguageChanged =  new EventEmitter();

  constructor(
    private contentService:ContentService,
    private directusService:DirectusService,
    ) {
      this.getTextFromDB();
  }
  changeLanguageTo(val:number){
    
    this.langID = val;

    switch(this.langID){
      case 0:
        this.langName = "de";
        break;
      case 1:
        this.langName = "en";
        break;
      case 2:
        this.langName = "fr";
        break;
    }
    
    document.documentElement.setAttribute('lang', this.langName);
    this.onLanguageChanged.emit();
  }


  async getTextFromDB(){
    const loggendIN = await this.directusService.loginIfNeeded();

    this.directusService.api.getItems<Textelement[]>("app_texts").then(result=>{
      this.textElements = result.data;
    });
  }

  getTextByKey(key:string):string{

    var val = null;

    if(this.textElements){
      this.textElements.forEach(element=>{
        if(element.key == key){
           val = this.getTextByLang(element);
           return;
        }
      });
      if(val==null)console.log(key + " Not Found");
    }
    
    if(val == null){
      this.defaultTE.forEach(elementTE=>{
        if(elementTE.key == key){
           val = elementTE.german;
           return;
        }
      });
    }

    if(val ==null){
      val = key;
    }

    return val;
  }

  getTextByLang(elm:Textelement):string{
    var val = null;
    switch(this.langID){
      case 0:
        val = elm.german;
        break;
      case 1:
        val = elm.english;
        break;
      case 2:
        val = elm.french;
        break;
    }

    if(val == null){
      val = elm.german + "("+ this.langName +")"
    }

    return val;
  }
}
