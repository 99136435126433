import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Component, Input, OnInit } from '@angular/core';
import { FilePreviewOverlayService } from 'src/app/services/file-preview-overlay-service.service';
import { MediaFile } from 'src/app/types/station';
import { FilePreviewOverlayComponent } from '../file-preview-overlay/file-preview-overlay.component';


@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.css']
})
export class ImageComponent implements OnInit {

  @Input() mediaFile:MediaFile;
  @Input() caption:string;
  @Input() altText:string;
  @Input() cropped:boolean;

  constructor(private previewDialog: FilePreviewOverlayService) { }
  
  openOverlay(){
    this.previewDialog.openImageOverlay(this.mediaFile,this.caption);
  }

  ngOnInit(): void {
    
  }

}
