import { Injectable } from '@angular/core';
import { IItemsResponse } from '@directus/sdk-js/dist/types/schemes/response/Item';
import { Observable, from } from 'rxjs';
import { MediaFile, VideoWithSub } from '../types/station';
import { DirectusService } from './directus.service';
import { QueryParams as QueryParamsType, AssetQueryParams as AssetQueryParamsType } from "@directus/sdk-js/dist/types/schemes/http/Query";
import { ContentService } from './content.service';
import { MediaFileService } from './media-file.service';


@Injectable({
  providedIn: 'root'
})
export class VideoSubtitleServiceService {

  constructor(private directusService:DirectusService, private contentService:ContentService, private mediaFileService:MediaFileService) { }

  async getSubtitleFilesForVideo(mediaFile:MediaFile):Promise<MediaFile>{

    if(mediaFile.subtitleFiles == null){

      mediaFile.subtitleFiles = [];
      console.log("FILEID:" +mediaFile.fileID);


      var vidWithSub =  await this.getVideosWithSubByVideoID(mediaFile.fileID).toPromise();
      console.log("WWWW");
      console.log(vidWithSub);
      if(vidWithSub.data && vidWithSub.data.length > 0){
        
       
        
        if(vidWithSub.data[0].subtitle_de){
          
            var f = await this.getSubtitleFile(vidWithSub.data[0].subtitle_de);

            mediaFile.subtitleFiles.push(f);
        
        }else{
          mediaFile.subtitleFiles.push(null);
        }

        if(vidWithSub.data[0].subtitle_en){
          var f = await this.getSubtitleFile(vidWithSub.data[0].subtitle_en);
          mediaFile.subtitleFiles.push(f);
        }else{
          mediaFile.subtitleFiles.push(null);
        }

        if(vidWithSub.data[0].subtitle_fr){
          var f = await this.getSubtitleFile(vidWithSub.data[0].subtitle_fr);
          mediaFile.subtitleFiles.push(f);
        }else{
          mediaFile.subtitleFiles.push(null);
        }
      }
    }

    return mediaFile;
  } 


  async getSubtitleFile(fileID:number):Promise<MediaFile>{
    var file = await this.contentService.getFiles(fileID).toPromise();
    console.log("FILE");
    console.log(file);
    var mFile = this.mediaFileService.CreateMediaFile(file.data[0]);
    console.log(mFile);
    return mFile;
  }


  getVideosWithSubByVideoID(videoID:number):Observable<IItemsResponse<VideoWithSub[]>>{
    const params: QueryParamsType = {
      filter:{
        ["video_file"]:{eq: videoID}
      }
    }
    return from(this.directusService.api.getItems<VideoWithSub[]>('video_contents',params));
  }

  getVideosWithSubByID(videoSubID:number):Observable<IItemsResponse<VideoWithSub[]>>{
    const params: QueryParamsType = {
      filter:{
        ["id"]:{eq: videoSubID}
      }
    }
    return from(this.directusService.api.getItems<VideoWithSub[]>('video_contents',params));
  }

  

}
