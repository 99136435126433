import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FeedbackService } from 'src/app/services/feedback.service';
import { LanguageService } from 'src/app/services/language.service';
import { Catergory, Feedback } from 'src/app/types/feedback';
import { Textelement } from 'src/app/types/textData';

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.css']
})
export class FeedbackFormComponent implements OnInit {
  @Output() send = new EventEmitter<Feedback>();

  @ViewChild('dropdownContent', {read: ElementRef, static: false})
  dropdownContent: ElementRef = null;

  messageForm;
  messageError = false;
  nameError = false;
  errorMessage = null

  constructor(
    public languageService:LanguageService,
    public feedbackService:FeedbackService,
    public formBuilder: FormBuilder,
  ) {
    this.messageForm = this.formBuilder.group({
      name: '',
      message: ''
    });
  }

  categories:Textelement[];
  selectedCategory:string;
  selectedCategoryID:number = null;

  ngOnInit(): void {
    this.feedbackService.login().then(result=>{
      this.feedbackService.getCategories().subscribe(result=>{
        this.categories = result.data;
      });
    });
  }


  dropdownOpen:boolean = false;
  openDropdown(){
    this.dropdownOpen = !this.dropdownOpen;
    this.dropdownContent.nativeElement.style.display = this.dropdownOpen ? "block" : "";
  }

  onDropdownSelect(index:number){
    this.selectedCategory = this.categories[index].german;
    this.selectedCategoryID = index;
    this.openDropdown();
  }

  onSubmit(data) {

    if(data.message != ""){
      var feedback = this.CreateFeedback(data.name,data.message);
      this.feedbackService.sendFeedback(feedback);

      this.messageError = false;
      //this.nameError = false;
      this.errorMessage = null;
      this.messageForm.reset();

      this.send.emit(feedback);

    }else{
      this.messageError = data.message == "";
      //this.nameError = data.name == "";
      /*
      if(this.nameError){
        this.errorMessage = this.languageService.getTextByKey("FEEDBACK_FORM_MISSINGNAME");
      }
      */
      if(this.messageError){
        this.errorMessage = this.languageService.getTextByKey("FEEDBACK_FORM_MISSINGMESSAGE");
      }
      /*
      if(this.messageError && this.nameError){
        this.errorMessage =  this.languageService.getTextByKey("FEEDBACK_FORM_MISSINGNAME") + "/n" + this.languageService.getTextByKey("FEEDBACK_FORM_MISSINGNAME");
      }*/
    } 
  }

  CreateFeedback(name:string, message:string):Feedback{
    var feedback = <Feedback>{};

    var date = new Date();
    
    feedback.creation_day = date.getDate() + "." + (date.getMonth()+1) + "." +date.getFullYear();
    feedback.creator_name = name;
    feedback.message = message;
    feedback.categorie_id = this.selectedCategoryID;
    
    return feedback;
  }

}
