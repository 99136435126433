import { Component, Input, OnInit } from '@angular/core';
import { ContentService } from 'src/app/services/content.service';
import { LanguageService } from 'src/app/services/language.service';
import { MainImage, Station } from 'src/app/types/station';

@Component({
  selector: 'app-main-image',
  templateUrl: './main-image.component.html',
  styleUrls: ['./main-image.component.css']
})
export class MainImageComponent implements OnInit {
  @Input() mainImage:MainImage;
  showMore = false;

  constructor(
    public languageService:LanguageService,
    public contentService:ContentService
  ) { }

  ngOnInit(): void {
  }

  toogleMore(){
    this.showMore = !this.showMore;
  }
}
