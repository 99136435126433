<div *ngIf="additional.translations[languageService.langID].mediaFiles && additional.translations[languageService.langID].mediaFiles.length > 0"> 
    <app-image  *ngIf="additional.translations[languageService.langID].mediaFiles[index].mediaType.includes('image')" 
        [mediaFile]="additional.translations[languageService.langID].mediaFiles[index]" 
        [altText]="additional.translations[languageService.langID].alt_text"
        [caption]="additional.translations[languageService.langID].caption">
    </app-image>
    <app-video  *ngIf="additional.translations[languageService.langID].mediaFiles[index].mediaType.includes('video')" 
        [mediaFile]="additional.translations[languageService.langID].mediaFiles[index]" 
        [altText]="additional.translations[languageService.langID].alt_text"
        [caption]="additional.translations[languageService.langID].caption">
    </app-video>
    <app-audio  *ngIf="additional.translations[languageService.langID].mediaFiles[index].mediaType.includes('audio')" 
        [mediaFile]="additional.translations[languageService.langID].mediaFiles[index]" 
        [altText]="additional.translations[languageService.langID].alt_text"
        [caption]="additional.translations[languageService.langID].caption">
    </app-audio>
    <div *ngIf="additional.translations[languageService.langID].mediaFiles.length > 1" id="nextPrevButtons">
        <button [disabled]="index < 1" (click)="showPrevImage()"><img src="./assets/Icons/iconfinder_left_4829874.svg" width="100%" height="100%"></button>
        <button [disabled]="index >= additional.translations[languageService.langID].mediaFiles.length-1" (click)="showNextImage()"><img src="./assets/Icons/iconfinder_right_4829872.svg"width="100%" height="100%"></button>
    </div>

    <!--<div [ngClass]="'caption'">{{additional.translations[languageService.langID].caption}}</div>-->
</div>

<h2>
    <ng-container *ngIf="contentService.isEmpty(additional.translations[languageService.langID].description)">
        {{additional.translations[languageService.langID].headline}}
    </ng-container>
    <ng-container *ngIf="!contentService.isEmpty(additional.translations[languageService.langID].description)">
        <button class=textButton (click)="toogleMore()">
            {{additional.translations[languageService.langID].headline}}&nbsp;<span [ngClass]="[!showMore ? 'moreIcon' : 'lessIcon']"></span>
        </button>
    </ng-container> 
</h2>

<div *ngIf="showMore" [ngClass]="'moreInfo'">
    <div *ngIf="additional.translations[languageService.langID].description != ''" [innerHTML]="additional.translations[languageService.langID].description">
    </div>

</div>