<h1>{{languageService.getTextByKey("MENU_FEEDBACK")}}</h1>

<div *ngFor="let feedback of feedbackData">
    <app-feedback-block [feedbackData]=feedback></app-feedback-block>
</div>

<hr>

<app-feedback-form *ngIf="!feedbackSend" (send)="onFeedbackSend($event)"></app-feedback-form>
<div *ngIf="feedbackSend">
    <h2>{{languageService.getTextByKey("FEEDBACK_AFTERSEND_HEADLINE")}}</h2> 
    <p>{{languageService.getTextByKey("FEEDBACK_AFTERSEND_SUBLINE")}}</p>
</div>

