<div *ngIf="ready && station && station.translationsReady" [ngClass]="appstateService.isSmallScreen ? 'mobile' : 'desktop'">
    <h1 *ngIf="!appstateService.isSmallScreen">{{station.translations[languageService.langID].headline}}</h1>
   
    <div id="contentGrid">

        <app-main-image *ngIf="station.mainImage" [ngClass]="'content-block'" [mainImage]=station.mainImage></app-main-image>
    
        <app-audio-guide *ngIf="station.audioGuide" [ngClass]="'content-block'" [audioGuide]=station.audioGuide [stationIndex]=station.station_number></app-audio-guide>

        <ng-container *ngIf="appstateService.isMobile"><app-path-to-next-station [station]="station"></app-path-to-next-station></ng-container>

        <ng-container *ngFor="let additional of station.additionalContent">
            <app-additional-content  *ngIf="(additional != null && additional.translations[languageService.langID] && additional.translations[languageService.langID].headline != '')  && (!appstateService.isInMusuem || !additional.only_at_home)" [ngClass]="'content-block'" [additional]=additional></app-additional-content>
        </ng-container>
    </div>
</div>