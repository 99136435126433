import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { AppstateService } from 'src/app/services/appstate.service';
import { LanguageService } from 'src/app/services/language.service';


@Component({
  selector: 'app-at-home-selection',
  templateUrl: './at-home-selection.component.html',
  styleUrls: ['./at-home-selection.component.css']
})
export class AtHomeSelectionComponent implements OnInit {

  stepID:Number = 0;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appstateService:AppstateService,
    public languageService:LanguageService,

    ) { }

  onStartButtonClicked(buttonIndex: number){
    switch (buttonIndex){
      case 0:
        this.stepID = 1;
        break;
      case 1:
        this.appstateService.isInMusuem = true;
        this.router.navigate(['/station/'+this.appstateService.currentStationId]);
          break;
      case 2:
        this.appstateService.isInMusuem = false;
        this.router.navigate(['/station/'+this.appstateService.currentStationId]);
          break;
    }
  }

  ngOnInit(): void {
  }

}
