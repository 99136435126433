import { Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Station } from '../types/station';
import { StationService } from './station.service';
import { Console } from 'console';
import { HostListener } from '@angular/core';
import { FilePreviewOverlayService } from './file-preview-overlay-service.service';
import { ComponentPortal } from '@angular/cdk/portal';
import { FilePreviewOverlayComponent } from '../elements/file-preview-overlay/file-preview-overlay.component';


@Injectable({
  providedIn: 'root'
})
export class AppstateService {

  

  constructor(
    private breakpointObserver:BreakpointObserver,
    private router:Router,
    private stationService:StationService,
    private overlayDialog: FilePreviewOverlayService,
    )
    {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      this.checkIfMobile();
      breakpointObserver.observe('(max-width: 499px)').subscribe(result => {
        this.checkScreen();
      });

      breakpointObserver.observe('(max-height: 499px)').subscribe(result => {
        this.checkScreen();
      });

      router.events.subscribe((val)=>{
        if(val instanceof NavigationEnd){

          if(val.urlAfterRedirects != "/menu"){
            this.isMenuOpen = false;
          }

          if(val.urlAfterRedirects != "/menu" && val.urlAfterRedirects != "/portrait"){
            this.currentRoute = val.urlAfterRedirects;
          }
          
          //this.whitePage.next(val.url != "/station");
          this.isStation = val.url.includes("/station"); 
          this.isWhitePage = this.checkIfStation(val.url);
          this.isStartPage = val.url.includes("/start") || val.url == "/";
        }
      });
      //this.whitePage = new BehaviorSubject<boolean>(false);
    }

  public currentRoute:string = "/";
  public isInMusuem:Boolean = null;
  public isSmallScreen = this.breakpointObserver.isMatched('(max-width: 599px)');
  public isMobile = false;
  public isMenuOpen:boolean = false;
  public isStartPage:boolean = true;
  public isStation:boolean = false;
  public isWhitePage:boolean = false;

  public currentStation:Station;
  public currentStationId:number = 1;
  public isFirstStation:boolean = false;
  public isLastStation:boolean = false;

  portraitOverlayOpend:boolean = false;



  public checkScreen(){
    this.isSmallScreen = this.breakpointObserver.isMatched('(max-width: 599px)');

    if(this.isSmallScreen && !this.checkIfPortrait()){
      if(!this.portraitOverlayOpend)this.overlayDialog.openPortraitOverlay();
      this.portraitOverlayOpend = true;
    }else if(this.checkIfPortrait && this.portraitOverlayOpend){
      this.overlayDialog.closePotraitOverlay();
      this.portraitOverlayOpend = false;
    }
    this.checkIfMobile();
  }

  checkIfMobile(){
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.isMobile = true;
    }else{
      this.isMobile = false;
    }

    if(!this.isSmallScreen)this.isMobile = false;
    
    if(this.isMobile && this.checkIfPortrait()){
      this.isSmallScreen = true;
    }
  }

  checkIfPortrait():boolean{
    return window.outerWidth < window.outerHeight;
  }

  updateCurrentStation(newStationID:number){
    this.currentStationId = newStationID;
    this.currentStation = this.stationService.stations[newStationID]; 
    this.isFirstStation = newStationID == 0;
    this.isLastStation = newStationID >= this.stationService.stations.length-1;

    if(!this.currentStation.prepared)this.stationService.prepareStation(this.currentStation);

    setTimeout(()=>{this.setDistanceForNavbar();},2);
    

  }

  setDistanceForNavbar(){
    if(!this.isMobile)return;
    if(this.isStation){
      var nav = document.getElementById("nav-bar").offsetHeight;
    document.getElementById("main-content").style.paddingTop = nav + "px";
    }else{
      document.getElementById("main-content").style.paddingTop = "";
    }
    
  }

  checkIfStation(routeString:string):boolean{
    return routeString.includes("/station") || routeString.includes("/privacy") || routeString.includes("/imprint")
  }
}
