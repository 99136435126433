import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StationComponent } from './station-elements/station/station.component';
import { DirectusService } from './services/directus.service';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { LandingPageComponent } from './screens/landing-page/landing-page.component';
import { MainMenuComponent } from './menu/main-menu/main-menu.component';
import { MainMenuButtonComponent } from './menu/main-menu-button/main-menu-button.component';
import { HotelSilberLogoComponent } from './hotel-silber-logo/hotel-silber-logo.component';
import { AtHomeSelectionComponent } from './screens/at-home-selection/at-home-selection.component';
import { NavigationBarComponent } from './menu/navigation-bar/navigation-bar.component';
import { LanguageMenuComponent } from './menu/language-menu/language-menu.component';
import { PageNotFoundComponent } from './screens/page-not-found/page-not-found.component';
import { AudioGuideComponent } from './station-elements/audio-guide/audio-guide.component';
import { MainImageComponent } from './station-elements/main-image/main-image.component';
import { AdditionalContentComponent } from './station-elements/additional-content/additional-content.component';
import { PathToNextStationComponent } from './station-elements/path-to-next-station/path-to-next-station.component';
import { AboutComponent } from './screens/about/about.component';
import { HelpComponent } from './screens/help/help.component';
import { VisitComponent } from './screens/visit/visit.component';
import { ImprintComponent } from './screens/imprint/imprint.component';
import { HdgComponent } from './screens/hdg/hdg.component';
import { StationNavigationComponent } from './station-elements/station-navigation/station-navigation.component';
import { CodeComponent } from './screens/code/code.component';
import { FeedbackComponent } from './screens/feedback/feedback.component';
import { FeedbackFormComponent } from './elements/feedback-form/feedback-form.component';
import { FeedbackBlockComponent } from './elements/feedback-block/feedback-block.component';
import { ImageComponent } from './elements/image/image.component';
import { AudioComponent } from './elements/audio/audio.component';
import { VideoComponent } from './elements/video/video.component';
import { OverlayComponent } from './elements/overlay/overlay.component';
import { PortraitComponent } from './screens/portrait/portrait.component';
import { FilePreviewOverlayComponent } from './elements/file-preview-overlay/file-preview-overlay.component';
import { FilePreviewOverlayService } from './services/file-preview-overlay-service.service';

import { OverlayModule } from "@angular/cdk/overlay";
import { PrivacyComponent } from './screens/privacy/privacy.component';
import { MatSliderModule } from '@angular/material/slider';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [
    AppComponent,
    StationComponent,
    LandingPageComponent,
    MainMenuComponent,
    MainMenuButtonComponent,
    HotelSilberLogoComponent,
    AtHomeSelectionComponent,
    NavigationBarComponent,
    LanguageMenuComponent,
    PageNotFoundComponent,
    AudioGuideComponent,
    MainImageComponent,
    AdditionalContentComponent,
    PathToNextStationComponent,
    AboutComponent,
    HelpComponent,
    VisitComponent,
    ImprintComponent,
    HdgComponent,
    StationNavigationComponent,
    CodeComponent,
    FeedbackComponent,
    FeedbackFormComponent,
    FeedbackBlockComponent,
    ImageComponent,
    AudioComponent,
    VideoComponent,
    OverlayComponent,
    PortraitComponent,
    FilePreviewOverlayComponent,
    PrivacyComponent,
  ],
  imports: [
    MatSliderModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    OverlayModule,
    NoopAnimationsModule,
    
  ],
  providers: [FilePreviewOverlayService ],
  bootstrap: [AppComponent],entryComponents: [
    // Needs to be added here because otherwise we can't
    // dynamically render this component at runtime
    FilePreviewOverlayComponent,
    PortraitComponent
  ]
})
export class AppModule { }
