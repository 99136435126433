import { Injectable } from '@angular/core';
import DirectusSDK from '@directus/sdk-js';
import { from, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DirectusService {

  private internalSDKClient = new DirectusSDK({
    url: "https://klangerfinder.v135637.goserver.host/cms/public",
    //url:"http://benedikthaas.de/directus/public",
    project: "hotel-silber",
    //project: "testsetup",
    email: "api@benedikthaas.de",
    //password: "kypwax-0hepcy-guwfAm"
    password: "6uu@,$Y'X{}ENupA"
  } as any);

  async loginIfNeeded(){
    return this.checkLoginState().then(isLoggedIn=>{
      if(isLoggedIn == true){
        return true;
      }else{
        return this.login().then(result=>{
          return true;
        })
      }
    });
  }

  async checkLoginState(){
    const loggendIn = await this.checkStaus();
    return loggendIn;
  }


  async login(){
    return this.internalSDKClient.login({
      url: "https://klangerfinder.v135637.goserver.host/cms/public",
      //url:"http://benedikthaas.de/directus/public",
      project: "hotel-silber",
      //project: "testsetup",
      email: "api@benedikthaas.de",
      password: "6uu@,$Y'X{}ENupA"
    });
  }

  async checkStaus(){
    return this.internalSDKClient.isLoggedIn();
  }

  ping():Observable<string>{
    return from(this.internalSDKClient.ping());
  }
  
  public get api(): DirectusSDK {
    return this.internalSDKClient;
  }


}
