import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppstateService } from 'src/app/services/appstate.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  constructor(
    public appstateService:AppstateService,
    public languageService:LanguageService,
    title:Title) {
      title.setTitle("HOTEL SILBER: HOME");
    }

  ngOnInit(): void {
  }

}