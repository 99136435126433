import { Component, Input, OnInit } from '@angular/core';
import { FeedbackService } from 'src/app/services/feedback.service';
import { LanguageService } from 'src/app/services/language.service';
import { Catergory, Feedback } from 'src/app/types/feedback';
import { Textelement } from 'src/app/types/textData';

@Component({
  selector: 'app-feedback-block',
  templateUrl: './feedback-block.component.html',
  styleUrls: ['./feedback-block.component.css']
})
export class FeedbackBlockComponent implements OnInit {

  @Input() feedbackData:Feedback;

  categorie:Textelement;
  constructor(public feedbackService:FeedbackService, public languageService:LanguageService) { }

  ngOnInit(): void {
    this.feedbackService.getCategorie(this.feedbackData.categorie_id).subscribe(result=>{
      this.categorie = result.data;
    })
  }

}
