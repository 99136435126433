<h1>{{languageService.getTextByKey("MENU_NUMBLOCK")}}</h1>


<div id="enterd">
    {{enterdNumber}}
</div>

<div class="button-group">
    <button class="circleButton numbersButton" (click)="setNumber('1')">1</button>
    <button class="circleButton numbersButton" (click)="setNumber('2')">2</button>
    <button class="circleButton numbersButton" (click)="setNumber('3')">3</button>
    <button class="circleButton numbersButton" (click)="setNumber('4')">4</button>
    <button class="circleButton numbersButton" (click)="setNumber('5')">5</button>
    <button class="circleButton numbersButton" (click)="setNumber('6')">6</button>
    <button class="circleButton numbersButton" (click)="setNumber('7')">7</button>
    <button class="circleButton numbersButton" (click)="setNumber('8')">8</button>
    <button class="circleButton numbersButton" (click)="setNumber('9')">9</button>
    <button [disabled]="enterdNumber == ''" class="circleButton numbersButton" (click)="removeLast()">x</button>
    <button class="circleButton numbersButton" (click)="setNumber('0')">0</button>
    <button [disabled]="enterdNumber == ''"class="circleButton numbersButton" (click)="loadStation()">></button>
</div>
