import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppstateService } from 'src/app/services/appstate.service';
import { LanguageService } from 'src/app/services/language.service';
import { StationService } from 'src/app/services/station.service';

@Component({
  selector: 'app-station-navigation',
  templateUrl: './station-navigation.component.html',
  styleUrls: ['./station-navigation.component.css']
})
export class StationNavigationComponent implements OnInit {

  constructor(
   public appstateService:AppstateService,
   private stationService:StationService,
   private router: Router,
   public languageService:LanguageService,

  ) { }

  ngOnInit(): void {
  }

  onBackClicked(){
    var newID = this.appstateService.currentStationId -= 1;
    this.showNewStation(newID);
  }

  onNextClicked(){
    var newID = this.appstateService.currentStationId += 1;
    this.showNewStation(newID);
  }

  onShowFeedback(){
    this.router.navigate(["feedback/"]);
  }

  showNewStation(newID:number){
    this.stationService.getStationNumberByStationID(newID).then(result=>{
      this.router.navigate(["station/"+result]);
    });

  
   
  }
}
