<div class="mobile" *ngIf="appstateService.isSmallScreen">
    <app-hotel-silber-logo id="logo"></app-hotel-silber-logo>
    <nav><button class="roundButton" routerLink="/atHome">{{languageService.getTextByKey("STATION_LANDING_STARTTOUR")}}</button></nav>
</div>

<div class="desktop" *ngIf="!appstateService.isSmallScreen">
    <app-main-menu [isStartPage]=true ></app-main-menu>
    <div class="focusOnInit"><button class="roundButton" routerLink="/station">{{languageService.getTextByKey("STATION_LANDING_STARTTOUR")}}</button></div>   
</div>


