<h2>{{languageService.getTextByKey("FEEDBACK_FORM_HEADLINE")}}</h2>
<h3>{{languageService.getTextByKey("FEEDBACK_FORM_SUBHEADLINE")}}</h3>
<div class="dropdown">
  <button class="dropbtn" [ngClass]="[dropdownOpen ? 'opend' : 'closed']" (click)="openDropdown()">
    <span *ngIf="selectedCategoryID==null">{{languageService.getTextByKey("FEEDBACK_BTN_CATEGORY")}}</span>
    <span *ngIf="selectedCategoryID!=null">{{languageService.getTextByLang(categories[selectedCategoryID])}}</span>
  </button>
  <div #dropdownContent class="dropdown-content">
    <ul>
      <li *ngFor="let category of categories, let i = index">
        <a (click)="onDropdownSelect(i)">{{languageService.getTextByLang(category)}}</a>
      </li>
    </ul>
  </div>
</div>

<form [formGroup]="messageForm" (ngSubmit)="onSubmit(messageForm.value)">
    <div>
      <label for="name" aria-label="Name">
        {{languageService.getTextByKey("FEEDBACK_FORM_NAME")}} <br>
      </label>
      <input [ngClass]="[nameError ? 'error' : '']" id="name" type="text" formControlName="name">
    </div>
  
    <div>
      <label for="message" aria-label="Feedback">
        {{languageService.getTextByKey("FEEDBACK_FORM_MESSAGE")}}<br>
      </label>
      <textarea [ngClass]="[messageError ? 'error': '']" id="message" name="message" rows="4" cols="50" formControlName="message"></textarea>
    </div>
  
    <button class="roundButton" type="submit">{{languageService.getTextByKey("FEEDBACK_FORM_SEND")}}</button>
  </form>
  <span *ngIf="errorMessage">{{errorMessage}}</span>