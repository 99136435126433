import { Route } from '@angular/compiler/src/core';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AppstateService } from 'src/app/services/appstate.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';


@Component({
  selector: 'app-main-menu-button',
  templateUrl: './main-menu-button.component.html',
  styleUrls: ['./main-menu-button.component.css']
})
export class MainMenuButtonComponent implements OnInit {
  
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public appstateService:AppstateService
    ) { }

  ngOnInit(): void {
  }

  onMenuButtonClick(){
    this.appstateService.isMenuOpen = true;
    if(this.appstateService.isSmallScreen){
      this.router.navigate(["/menu"]);
    }
  }

  onMenuCloseClick(){
    this.appstateService.isMenuOpen = false;
    this.router.navigate([this.appstateService.currentRoute]);
  }
}
