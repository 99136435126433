<!--<div *ngIf="mainImage.mediaFile">
    <img [src]="mainImage.mediaFile.filePathCropped" [alt]="mainImage.translations[languageService.langID].alt_text" width="100%" onContextMenu="return false;">
    <div [ngClass]="'caption'">© {{mainImage.translations[languageService.langID].caption}}</div>
    
</div>-->
<ng-container *ngIf="mainImage.translations[languageService.langID]">
    <app-image  *ngIf="mainImage.mediaFile" 
        [mediaFile]="mainImage.mediaFile" 
        [altText]="mainImage.translations[languageService.langID].alt_text"
        [caption]="mainImage.translations[languageService.langID].caption"
        [cropped]=true>
    </app-image>

    <ng-container *ngIf="mainImage.translations[languageService.langID].headline != ''">
        <ng-container *ngIf="contentService.isEmpty(mainImage.translations[languageService.langID].image_description)">
            <h2>{{mainImage.translations[languageService.langID].headline}}</h2>
        </ng-container>
        <ng-container *ngIf="!contentService.isEmpty(mainImage.translations[languageService.langID].image_description)">
            <h2>
                <button class=textButton (click)="toogleMore()">
                    {{mainImage.translations[languageService.langID].headline}}&nbsp;<span [ngClass]="[!showMore ? 'moreIcon' : 'lessIcon']"></span>
                </button>
            </h2>
        </ng-container>
    </ng-container> 
</ng-container>

