import { Component, DebugElement, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { AppstateService } from 'src/app/services/appstate.service';
import { CommonModule } from '@angular/common';

import { ContentService } from '../../services/content.service';
import { DirectusService } from '../../services/directus.service';
import { Station, MainImage, AudioGuide, AdditionalContent, AdditionalContentTranslation,StationTranslation, MainImageTranslation, AudioGuideTranslation } from '../../types/station';
import { AdditionalContentComponent } from '../additional-content/additional-content.component';
import { LanguageService } from 'src/app/services/language.service';
import { ResourceLoader } from '@angular/compiler';
import { StationService } from 'src/app/services/station.service';
import { Console } from 'console';
import { Title } from '@angular/platform-browser';



@Component({
  selector: 'app-station',
  templateUrl: './station.component.html',
  styleUrls: ['./station.component.css']
})
export class StationComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router:Router,
    private appstateService:AppstateService, 
    private contentService:ContentService,
    private directusService:DirectusService,
    private languageService:LanguageService,
    private stationService:StationService,
    private title:Title,
    )
    {
      if(appstateService.isSmallScreen){
        if(appstateService.isInMusuem == null){
          var id = this.route.snapshot.paramMap.get('id');
          if(id!=null){
            console.log(id);
            appstateService.currentStationId = +id;
          }
          this.router.navigate(["atHome"]);
        }
      }
    }

  station:Station;
  stationID:number = 0;
  languageIndex = 0;
  imageFile;
  audioFile;
  additionalfile;

  ready:boolean = true;

  ngOnInit(): void { 

    var id = this.route.snapshot.paramMap.get('id');
    if(id != null){
      this.stationService.getStationIdByStationNumber(parseInt(id)).then(result=>{
        if(result == -1){
          this.stationID = 0;
          id = "1";
        }else{
          this.stationID = result;
        }
        this.station = this.stationService.stations[this.stationID];
        this.appstateService.updateCurrentStation(this.stationID);
      });
      
    }else{
      this.stationService.getAllStation().then(result=>{
        this.station = this.stationService.stations[this.stationID];
        this.appstateService.updateCurrentStation(this.stationID);
      });
    }

    this.setTitle();

  }

  setTitle(){
    if(this.station && this.station.translationsReady){
      this.title.setTitle("HOTEL SILBER: "+ this.station.translations[this.languageService.langID].headline);
    }else{
      setTimeout(() => {
        this.setTitle();
      }, 100);
    }
    
  }
  
}