import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IItemResponse } from '@directus/sdk-js/dist/types/schemes/response/Item';
import { Observable, from } from 'rxjs';
import { Catergory, Feedback } from '../types/feedback';
import { Textelement } from '../types/textData';
import { DirectusService } from './directus.service';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  constructor(
    private directusService:DirectusService,  
    private http: HttpClient
    ) {}

    async login(){
      return this.directusService.loginIfNeeded();
    }

    getCategories():Observable<IItemResponse<Textelement[]>>{
      return from(this.directusService.api.getItems<Textelement[]>('feedback_categories'));
    }

    getCategorie(categoryKey:number):Observable<IItemResponse<Textelement>>{
      return from(this.directusService.api.getItem<Textelement>('feedback_categories',categoryKey));
    }

    getFeedback():Observable<IItemResponse<Feedback[]>>{
      return from(this.directusService.api.getItems<Feedback[]>('feedback'));
    }

    sendFeedback(newFeedback:Feedback){
      this.directusService.api.createItem<Feedback>('feedback',newFeedback);
    }
}
