import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { mainModule } from 'process';
import { FilePreviewOverlayService } from 'src/app/services/file-preview-overlay-service.service';
import { MediaFile } from 'src/app/types/station';

@Component({
  selector: 'app-file-preview-overlay',
  templateUrl: './file-preview-overlay.component.html',
  styleUrls: ['./file-preview-overlay.component.css']
})
export class FilePreviewOverlayComponent implements OnInit {


  constructor(private previewDialog: FilePreviewOverlayService, elmRef: ElementRef) {
    this.elmRef = elmRef;
  } 

  elmRef: ElementRef;

  public setMediaFile(file:MediaFile,caption:string){
    this.mediaFile = file;
    this.caption = caption;
  }

  mediaFile:MediaFile;
  caption:string;

  ngOnInit(): void {
    
    var endWidth = window.innerWidth * 0.8;
    var endHeight = window.innerHeight * 0.8;

    var img = document.getElementById("popupImg");

    /*if(this.mediaFile.width > this.mediaFile.height){
      if(this.mediaFile.width < endWidth)endWidth = this.mediaFile.width;
      endHeight = this.mediaFile.height * (endWidth / this.mediaFile.width);
    }

    if(this.mediaFile.width < this.mediaFile.height){
      if(this.mediaFile.height < endHeight)endHeight = this.mediaFile.height;      
      endWidth = this.mediaFile.width  * (endHeight / this.mediaFile.height );
    }
    */

    

    if(this.mediaFile.width > this.mediaFile.height){
      endHeight = this.mediaFile.height * (endWidth / this.mediaFile.width);
    }else{
      endWidth = this.mediaFile.width  * (endHeight / this.mediaFile.height );
    }

    img.style.width = endWidth + "px";
    img.style.height = endHeight + "px";

    var marginL = (window.innerWidth - endWidth) / 2;
    var marginT = (window.innerHeight - endHeight) / 2;

    img.style.marginLeft = marginL + "px";
    img.style.marginTop = marginT + "px";

    var closeBtn = document.getElementById("closeBtn");
    closeBtn.style.right = (marginL + 10) + "px";
    closeBtn.style.top = (marginT + 10) + "px";
    
    var cap = document.getElementById("caption");
    cap.style.top = (marginT + endHeight) + "px";
    cap.style.right = (marginL) + "px";
    cap.style.maxWidth = endWidth + "px"

  }

  close(){
    this.previewDialog.close();
  }


}
