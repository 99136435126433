import { ComponentRef, Injectable } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { FilePreviewOverlayComponent } from '../elements/file-preview-overlay/file-preview-overlay.component';
import { PortraitComponent } from '../screens/portrait/portrait.component';
import { MediaFile } from '../types/station';


@Injectable({
  providedIn: 'root'
})
export class FilePreviewOverlayService {

  constructor(private overlay: Overlay) { }

  overlayRef:OverlayRef;
  portraitRef:OverlayRef;

  openPortraitOverlay(){
    this.portraitRef = this.overlay.create();
    const portraitOverlay = new ComponentPortal(PortraitComponent);
    this.portraitRef.attach(portraitOverlay);
  }

  closePotraitOverlay(){
    if(this.portraitRef !=null){
      this.portraitRef.dispose();
    }
  }


  openImageOverlay(file:MediaFile, caption:string) {
    // Returns an OverlayRef which is a PortalHost
    this.overlayRef = this.overlay.create();

    // Create ComponentPortal that can be attached to a PortalHost
    const filePreviewPortal = new ComponentPortal(FilePreviewOverlayComponent);

    // Attach ComponentPortal to PortalHost

    const compRef: ComponentRef<FilePreviewOverlayComponent> = this.overlayRef.attach(filePreviewPortal);
    compRef.instance.setMediaFile(file,caption);

  }

  close(){
    if(this.overlayRef !=null){
      this.overlayRef.dispose();
    }
  }
}