import { Injectable } from '@angular/core';
import { MediaFile } from '../types/station';

@Injectable({
  providedIn: 'root'
})
export class MediaFileService {

  constructor() { }

  CreateMediaFile(element):MediaFile{

    console.log(element);

    var file = <MediaFile>{};
    file.fileID = element.id;
    file.filePath = element.data.full_url;
    file.filePathOrg = element.data.full_url;
    file.mediaType = element.type;
    file.duration = element.duration;
    file.height = element.height;
    file.width = element.width;
    file.hash = element.private_hash;
    file.fileName = element.filename_disk;
    file.title = element.title;
    file.subtitleFiles = null;

    try{
      if(file.mediaType !== null && file.mediaType.includes('image')){
        file.filePath = element.data.thumbnails[8].url;
        file.filePathCropped = element.data.thumbnails[7].url;
      }
    }catch{
      console.log("FAILED");
      console.log(file.fileID);
        console.log(file.mediaType);
    }

    console.log(file);

    return file;
  }
}
