<nav>
    <ul>
        <li><button class="icon-button btn-sep icon-info" routerLink="/about" (click)="onMenuButtonClick()">
            {{languageService.getTextByKey("MENU_ABOUT")}}
        </button></li>
        <li *ngIf="!isStartPage"><button class="icon-button btn-sep icon-audio" routerLink="/station" (click)="onMenuButtonClick()">
            {{languageService.getTextByKey("MENU_AUDIOGUIDE")}}
        </button></li>
        <li><button class="icon-button btn-sep icon-help" routerLink="/help" (click)="onMenuButtonClick()">
            {{languageService.getTextByKey("MENU_HELP")}}
        </button></li>
        
        <li><button class="icon-button btn-sep icon-privacy" routerLink="/privacy" (click)="onMenuButtonClick()">
            {{languageService.getTextByKey("MENU_PRIVACY")}}
        </button></li>
        <!--
        <li><button class="icon-button btn-sep icon-house" routerLink="/HdG" (click)="onMenuButtonClick()">
            {{languageService.getTextByKey("MENU_HDG")}}
        </button></li>
        <li><button class="icon-button btn-sep icon-visit" routerLink="/visit" (click)="onMenuButtonClick()">
            {{languageService.getTextByKey("MENU_VISIT")}}
        </button></li>
        -->
        <li><button class="icon-button btn-sep icon-imprint" routerLink="/imprint" (click)="onMenuButtonClick()">
            {{languageService.getTextByKey("MENU_IMPRINT")}}
        </button></li>
        <li><button class="icon-button btn-sep icon-numblock" routerLink="/code" (click)="onMenuButtonClick()">
            {{languageService.getTextByKey("MENU_NUMBLOCK")}}
        </button></li>
        <li *ngIf="!isStartPage"><button class="icon-button btn-sep icon-feedback" routerLink="/feedback" (click)="onMenuButtonClick()">
            {{languageService.getTextByKey("MENU_FEEDBACK")}}
        </button></li>

    </ul>
</nav>
