import { Component, Input, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';
import { Station } from 'src/app/types/station';

@Component({
  selector: 'app-path-to-next-station',
  templateUrl: './path-to-next-station.component.html',
  styleUrls: ['./path-to-next-station.component.css']
})
export class PathToNextStationComponent implements OnInit {

  @Input() station:Station;
  showMore = false;

  toogleMore(){
    this.showMore = !this.showMore;
  }


  constructor(
    public languageService:LanguageService,
  ) { }

  ngOnInit(): void {
  }

}
