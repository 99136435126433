import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IItemResponse, IItemsResponse } from '@directus/sdk-js/dist/types/schemes/response/Item';
import { Observable, from } from 'rxjs';
import { Feedback } from '../types/feedback';
import { About, AboutTranslation, Hdg, Help, HelpTranslation, Imprint, ImprintTranslation, Privacy, PrivacyTranslation, Visit } from '../types/screens';
import { DirectusService } from './directus.service';
import { QueryParams as QueryParamsType, AssetQueryParams as AssetQueryParamsType } from "@directus/sdk-js/dist/types/schemes/http/Query";


@Injectable({
  providedIn: 'root'
})
export class OtherContentService {
  
  constructor(
    private directusService:DirectusService,  
    private http: HttpClient
    ) {}

  async login(){
    return this.directusService.loginIfNeeded();
  }

  async getImprintContent():Promise<Observable<IItemResponse<Imprint[]>>>{
    const neededLogin = await this.login();
    return from(this.directusService.api.getItems<Imprint[]>('impressum'));
  }

  async getImprintContentTranslation(imprintID:number,lang:string):Promise<Observable<IItemResponse<ImprintTranslation[]>>>{
    const neededLogin = await this.login();

    const params: QueryParamsType = {
      filter:{
        ["impressum_id"]:{eq: imprintID},
        ["language"]:{eq: lang}
      }
    }

    return from(this.directusService.api.getItems<ImprintTranslation[]>('impressum_translation',params));
  }

  CreateDefaultTranslationForImprint(element:Imprint):ImprintTranslation{
    var trans = <ImprintTranslation>{};
    trans.headline = element.headline;
    trans.imprint = element.imprint;
    return trans;
  }

  async getAboutContent():Promise<Observable<IItemResponse<About[]>>>{
    const neededLogin = await this.login();
    return from(this.directusService.api.getItems<About[]>('about'));
  }

  async getAboutContentTranslation(aboutID:number,lang:string):Promise<Observable<IItemResponse<AboutTranslation[]>>>{
    const neededLogin = await this.login();

    console.log("lang" +lang);
    const params: QueryParamsType = {
      filter:{
        ["about_id"]:{eq: aboutID},
        ["language"]:{eq: lang}
      }
    }

    return from(this.directusService.api.getItems<AboutTranslation[]>('about_translation',params));
  }

  getAboutToVideos(aboutID:number):Observable<IItemsResponse<any[]>>{
    const params: QueryParamsType = {
      filter:{
        ["about_id"]:{eq: aboutID}
      }
    }
    return from(this.directusService.api.getItems<any[]>('about_directus_files',params));
  }


  

  CreateDefaultTranslationForAbout(element:About):AboutTranslation{
    var trans = <AboutTranslation>{};
    trans.headline = element.headline;
    trans.main_text = element.main_text;
    return trans;
  }

  async getHelpContent():Promise<Observable<IItemResponse<Help[]>>>{
    const neededLogin = await this.login();
    return from(this.directusService.api.getItems<Help[]>('help'));
  }
  
  async getHelpContentTranslation(helpID:number,lang:string):Promise<Observable<IItemResponse<HelpTranslation[]>>>{
    const neededLogin = await this.login();

    const params: QueryParamsType = {
      filter:{
        ["help_id"]:{eq: helpID},
        ["language"]:{eq: lang}
      }
    }

    return from(this.directusService.api.getItems<HelpTranslation[]>('help_translation',params));
  }

  CreateDefaultTranslationForHelp(element:Help):HelpTranslation{
    var trans = <HelpTranslation>{};
    trans.headline = element.headline;
    trans.main_text = element.main_text;
    return trans;
  }  

  async getPrivacyContent():Promise<Observable<IItemResponse<Privacy[]>>>{
    const neededLogin = await this.login();
    return from(this.directusService.api.getItems<Privacy[]>('privacy'));
  }

  async getPrivacyContentTranslation(privacyID:number,lang:string):Promise<Observable<IItemResponse<PrivacyTranslation[]>>>{
    const neededLogin = await this.login();

    const params: QueryParamsType = {
      filter:{
        ["privacy_id"]:{eq: privacyID},
        ["language"]:{eq: lang}
      }
    }

    return from(this.directusService.api.getItems<PrivacyTranslation[]>('privacy_translation',params));
  }

  CreateDefaultTranslationForPrivacy(element:Privacy):PrivacyTranslation{
    var trans = <PrivacyTranslation>{};
    trans.headline = element.headline;
    trans.main_text = element.main_text;
    return trans;
  }  





  async getVisitContent():Promise<Observable<IItemResponse<Visit[]>>>{
    const neededLogin = await this.login();
    return from(this.directusService.api.getItems<Visit[]>('visit'));
  }

  async getHdgContent():Promise<Observable<IItemResponse<Hdg[]>>>{
    const neededLogin = await this.login();
    return from(this.directusService.api.getItems<Hdg[]>('hdg'));
  }


}
