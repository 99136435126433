import { chainedInstruction } from '@angular/compiler/src/render3/view/util';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { read } from 'fs';
import { element } from 'protractor';
import { AppstateService } from 'src/app/services/appstate.service';
import { MediaFile } from 'src/app/types/station';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSliderChange } from '@angular/material/slider';

@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.css']
})
export class AudioComponent implements OnInit {

  @Input() mediaFile:MediaFile;
  @Input() caption:string;
  @Input() altText:string;
  @Input() headline:string;
  @Input() resize:boolean = false;


  constructor(
    public appstateService:AppstateService,
  ) { }

  ngOnInit(): void {
    
  }

  ngAfterViewInit():void{
    if(this.resize){
      setTimeout(() => {this.setPlayerHeight(), 1});
    }
  }

  setPlayerHeight(){
    const elm = document.getElementById('audioGuidePlayer');
    var w = elm.offsetWidth;
    var h = w / 1.33333;

    elm.style.height = h + "px";
  }

  @ViewChild('audioPlayer', {read: ElementRef, static: false})
  audioPlayerRef: ElementRef = null;
/*
  @ViewChild('timeline', {read: ElementRef, static: false})
  timelineRef: ElementRef = null;

  @ViewChild('timebar', {read: ElementRef, static: false})
  timebarRef: ElementRef = null;
*/
  @ViewChild('playbar', {read: ElementRef, static: false})
  playbarRef: ElementRef = null;

  value = 0;

  onSliderChange(event) {
    //this.value = event.value;
    if(this.isPlaying()){
      var n =  this.audioPlayerRef.nativeElement.duration * (event.srcElement.value/100);
      this.audioPlayerRef.nativeElement.currentTime = n;
    }
  }

  onAudioPlay(){
    if(!this.isPlaying()){
      this.audioPlayerRef.nativeElement.play();
      this.playing = true;
      this.updateTimer();
    }else{
      this.playing = false;
      this.audioPlayerRef.nativeElement.pause();
    } 
  }

  onSeekBack(){
    if(this.isPlaying()){
      this.audioPlayerRef.nativeElement.currentTime -=10;
    }
  }

  onSeekForward(){
    if(this.isPlaying()){
      this.audioPlayerRef.nativeElement.currentTime +=10;
    }
  }

  onAudioPause(){
  }

  public playing:boolean = false;
  
  isPlaying():boolean {
    return this.audioPlayerRef.nativeElement
    && this.audioPlayerRef.nativeElement.currentTime > 0
    && !this.audioPlayerRef.nativeElement.paused
    && !this.audioPlayerRef.nativeElement.ended
    && this.audioPlayerRef.nativeElement.readyState > 2;
  }

  currentTime:string = "00:00";
  updateTimer(){

    if(!this.isPlaying){
      this.playing = false;
      return;
    }

    this.currentTime = this.convertToTimeString(this.audioPlayerRef.nativeElement.currentTime);

    var pastPercentage = (this.audioPlayerRef.nativeElement.currentTime / this.audioPlayerRef.nativeElement.duration)*100;

    //this.timelineRef.nativeElement.style.width = pastPercentage + "%";
    //this.timebarRef.nativeElement.style.width = 100 - pastPercentage + "%";

    this.playbarRef.nativeElement.value = pastPercentage;

    setTimeout(() => {
      this.updateTimer();
    }, 500);
  }

  convertToTimeString(val:number):string{

    var min = Math.floor(val / 60);
    var sec =  Math.floor(val % 60);

    var s = min.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false}) + ":" + sec.toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false});

    return s;
  }



}
