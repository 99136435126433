<div id="contentArea">
    <ng-container *ngIf="!appstateService.isMenuOpen && appstateService.isSmallScreen">
        <h1 *ngIf="appstateService.isStation && appstateService.currentStation">{{appstateService.currentStation.headline}}</h1>
    </ng-container>
    <span *ngIf="appstateService.isMenuOpen"><img src="./assets/Icons/Icons_Hotel_Silber_Logo_small.svg" alt="Logo Hotel Silber" width="50%"/></span>
</div>

<div id="buttonArea">
    <ng-container>
        <button *ngIf="!appstateService.isMenuOpen" (click)="onMenuButtonClick()"><img src="./assets/Icons/Icons_Hotel_Silber_open.svg" alt="Hauptmenü öffnen" width="100%" height="100%"/></button>
        <button *ngIf="appstateService.isMenuOpen" (click)="onMenuCloseClick()"><img src="./assets/Icons/Icons_Hotel_Silber_close.svg" alt="Hauptmenü schliessen" width="100%" height="100%"/></button>
    </ng-container>
</div>