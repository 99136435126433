<div class="messageBlock">
    <h3>
        <ng-container *ngIf="feedbackData.creator_name">
            {{languageService.getTextByKey("FEEDBACK_ELEMENT_NAME_BEVORE")}} 
            {{feedbackData.creator_name}}
        </ng-container> 
        <ng-container *ngIf="!feedbackData.creator_name">
            {{languageService.getTextByKey("FEEDBACK_ELEMENT_NONAME")}} 
            {{feedbackData.creator_name}}
        </ng-container> 
        <ng-container *ngIf="categorie">
            {{languageService.getTextByKey("FEEDBACK_ELEMENT_NAME_AFTER")}} 
            {{languageService.getTextByLang(categorie)}}
        </ng-container> 
    </h3>
    <h3>{{feedbackData.creation_day}}</h3>
    <p>{{feedbackData.message}}</p>
</div> 