import { Component, DebugElement, OnInit } from '@angular/core';
import { resolve } from 'dns';
import { ContentService } from 'src/app/services/content.service';
import { LanguageService } from 'src/app/services/language.service';
import { MediaFileService } from 'src/app/services/media-file.service';
import { OtherContentService } from 'src/app/services/other-content.service';
import { VideoSubtitleServiceService } from 'src/app/services/video-subtitle-service.service';
import { About } from 'src/app/types/screens';
import { MediaFile } from 'src/app/types/station';
import { BrowserModule, DomSanitizer, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(
    public otherContentService:OtherContentService,
    public contentService:ContentService,
    public languageService:LanguageService,
    private videoSubService:VideoSubtitleServiceService,
    private mediaFileService:MediaFileService,
    private title:Title,
    public santatize:DomSanitizer) { }

  data:About;

  

  ngOnInit(): void {

    this.otherContentService.getAboutContent().then(result=>{
      
      result.subscribe(contentData=>{
        console.log(contentData);
        this.data = contentData.data[0];

        this.data.translations = [this.otherContentService.CreateDefaultTranslationForAbout(this.data)];

        this.otherContentService.getAboutContentTranslation(this.data.id,"en").then(result=>{
          result.subscribe(contentData=>{
            console.log(contentData);
            if(contentData.data.length > 0){
              this.data.translations.push(contentData.data[0]);
            }else{
              this.data.translations.push(this.otherContentService.CreateDefaultTranslationForAbout(this.data));
            }
          })
          this.otherContentService.getAboutContentTranslation(this.data.id,"fr").then(result=>{
            result.subscribe(contentData=>{
              console.log(contentData);
              if(contentData.data.length > 0){
                this.data.translations.push(contentData.data[0]);
              }else{
                this.data.translations.push(this.otherContentService.CreateDefaultTranslationForAbout(this.data));
              }
              
              if(this.data.translations[0].main_text.includes("[VIDEOINSERT_")){

                this.data.translations[0].main_text = this.replaceInserTag(this.data.translations[0].main_text);
                this.data.translations[1].main_text = this.replaceInserTag(this.data.translations[1].main_text);
                this.data.translations[2].main_text = this.replaceInserTag(this.data.translations[2].main_text);

                this.otherContentService.getAboutToVideos(this.data.id).subscribe(result=>{
                  console.log("RESULT IS ");
                  console.log(result.data);
                  
                  this.getVideos(result);
                
                });
              }else{
                console.log(this.data.translations[0].main_text);
              }
              this.title.setTitle("HOTEL SILBER: "+this.data.translations[this.languageService.langID].headline);
              console.log(this.data);
            })
          });
        });

      })
    });
  }

  replaceInserTag(original){
    while(original.includes("[VIDEOINSERT_")){

      var s = original.indexOf("[VIDEOINSERT_");
      var id = original.substring(s+"[VIDEOINSERT_".length,s+"[VIDEOINSERT_".length+1);
      original = original.replace("[VIDEOINSERT_"+id+"]","<!--VIDEOINSERT_"+id+"-->");
    }

    return original;
  }


  async getVideos(result:any){
  
      const asyncForLoop = async (strList) => {
      for (const str of strList) {

        console.log("GET VIDEO FILE" + str.directus_files_id);

        var vid = await this.contentService.getFiles(str.directus_files_id).toPromise();
        var videoFile = this.mediaFileService.CreateMediaFile(vid.data[0]);
        videoFile = await this.videoSubService.getSubtitleFilesForVideo(videoFile);

        var vidString = '<video controls="controls" width="100%" height="100%" crossorigin="anonymous">'+
                          "<source src='"+videoFile.filePath+"' type='video/mp4' />";

        if(videoFile.subtitleFiles !== null){
          
        var lID = this.languageService.langID;

       /* case 0:
        this.langName = "de";
        break;
      case 1:
        this.langName = "en";
        break;
      case 2:
        this.langName = "fr";
        break;*/

        if(videoFile.subtitleFiles[0]){
          vidString += "<track"
          if(lID == 0) vidString += " default";
          vidString += " label='deutsch' kind='subtitles' srclang='de' src='"+videoFile.subtitleFiles[0].filePath+"'>";
        } 
        if(videoFile.subtitleFiles[1]){
          vidString += "<track ";
          if(lID == 1) vidString += " default";
          vidString += " label='english' kind='subtitles' srclang='en' src='"+videoFile.subtitleFiles[1].filePath+"'>";
        } 
        if(videoFile.subtitleFiles[2]){
          vidString += "<track";
          if(lID == 2) vidString += " default";
          vidString += " label='français' kind='subtitles' srclang='fr' src='"+videoFile.subtitleFiles[2].filePath+"'>";
        } 
      }else{
        console.log("no subs at all" + videoFile);
      }
        vidString += "</video>";


        for(var langID = 0; langID < this.data.translations.length; langID++){
          if(this.data.translations[langID].main_text.includes("<!--VIDEOINSERT_")){
            var s = this.data.translations[langID].main_text.indexOf("<!--VIDEOINSERT_");
            var id = this.data.translations[langID].main_text.substring(s+"<!--VIDEOINSERT_".length,s+"<!--VIDEOINSERT_".length+1);
            console.log("REPLACE ID "+id);
            this.data.translations[langID].main_text = this.data.translations[langID].main_text.replace("<!--VIDEOINSERT_"+id+"-->",vidString);
            console.log(this.data.translations[langID].main_text);
          }
        }

        console.log("DONE FOR" );

      }
      console.log("done");
      }
      asyncForLoop(result.data);
  }

}




