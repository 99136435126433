import { Component, OnInit } from '@angular/core';
import { FeedbackService } from 'src/app/services/feedback.service';
import { LanguageService } from 'src/app/services/language.service';
import { Feedback } from 'src/app/types/feedback';
import { FormBuilder } from '@angular/forms';
import { Title } from '@angular/platform-browser';


@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  public feedbackData:Feedback[] = null;
  public feedbackSend:boolean = false;

  constructor(
    public languageService:LanguageService,
    public feedbackService:FeedbackService,
    private title:Title,
  ) {}

  ngOnInit(): void {

    this.feedbackService.login().then(result=>{
      this.feedbackService.getFeedback().subscribe(result=>{
        this.feedbackData = result.data;
        this.feedbackData.sort((a, b) => b.creation_day.localeCompare(a.creation_day));
      });
    });
    this.title.setTitle("HOTEL SILBER: "+this.languageService.getTextByKey("MENU_FEEDBACK"));
  }

  onFeedbackSend(event){
    this.feedbackSend = true;
  }
}
